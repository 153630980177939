import { Controller } from '@hotwired/stimulus'
import { html, render } from 'lit-html'
import { unsafeHTML } from 'lit-html/directives/unsafe-html.js'

export default class extends Controller {
  static values = { json: Object }
  static targets = ['container', 'phoneField']

  initialize() {
    this.containerTarget.innerHTML = ''
  }

  update() {
    // eslint-disable-next-line no-self-assign
    this.jsonValue = this.jsonValue
  }

  jsonValueChanged() {
    this.#render()
  }

  #render() {
    const data = this.jsonValue
    let style = `--primary-color: ${data.primary_color}; --secondary-color: ${data.secondary_color};`
    style += ` --tertiary-color: ${data.tertiary_color}; --quaternary-color: ${data.quaternary_color};`
    style += ` --btn-bg: var(--primary-color); --btn-text-color: var(--quaternary-color);`
    style += ` --input-border-color: var(--primary-color);`

    const content = html`
      <div
        class="bg-[--tertiary-color] p-3 pointer-events-none space-y-3"
        style="${style};  color: var(--secondary-color) !important;"
      >
        ${data.fields.map(
          (field) => html`
            <div>
              <label class="label !p-0 ${field.required && 'required'}">${field.label}</label>
              ${this.#fieldHtml(field)}
            </div>
          `,
        )}

        <div class="flex justify-end">
          <button type="button" class="btn">SUBMIT</button>
        </div>
      </div>
    `

    render(content, this.containerTarget)
  }

  #fieldHtml(field) {
    if (field.type === 'tel') {
      return html`${unsafeHTML(this.phoneFieldTarget.innerHTML)}`
    }

    if (field.type === 'select') {
      return html`
        <select type="${field.type}" class="input input-border" readonly>
          <option selected disabled hidden>Select</option>
        </select>
      `
    }

    return html`<input type="${field.type}" class="input input-border" placeholder="Type here" readonly />`
  }
}
